import { Language } from "../LangSelector/types";
import { FooterLinkType } from "./types";
import {
  TwitterIcon,
  TelegramIcon,
  RedditIcon,
  InstagramIcon,
  GithubIcon,
  DiscordIcon,
  MediumIcon,
  YoutubeIcon,
} from "../Svg";

export const footerLinks: FooterLinkType[] = [
  {
    label: "About",
    items: [
      {
        label: "Contact",
        href: "https://whitepaper.pepepal.com/contact-us",
      },
      {
        label: "Blog",
        href: "https://medium.com/@WutHonker",
      },
      {
        label: "Community",
        href: "https://whitepaper.pepepal.com/contact-us/telegram",
      },
      {
        label: "CAKE",
        href: "hhttps://whitepaper.pepepal.com/whitepeper-v1/token",
      },
      {
        label: "—",
      },
      {
        label: "Online Store",
        href: "https://pancakeswap.creator-spring.com/",
        isHighlighted: true,
      },
    ],
  },
  {
    label: "Help",
    items: [
      {
        label: "Customer",
        href: "Support https://whitepaper.pepepal.com/contact-us/customer-support",
      },
      {
        label: "Troubleshooting",
        href: "https://whitepaper.pepepal.com/help/troubleshooting",
      },
      {
        label: "Guides",
        href: "https://whitepaper.pepepal.com/get-started",
      },
    ],
  },
  {
    label: "Developers",
    items: [
      {
        label: "Github",
        href: "https://github.com/pancakeswap",
      },
      {
        label: "Documentation",
        href: "https://whitepaper.pepepal.com",
      },
    //  {
    //    label: "Bug Bounty",
    //    href: "https://app.gitbook.com/@pancakeswap-1/s/pancakeswap/code/bug-bounty",
    //  },
    //  {
    //    label: "Careers",
    //    href: "https://whitepaper.pepepal.com/hiring/become-a-pepepal",
    //  },
    ],
  },
];

export const socials = [
  {
    label: "Twitter",
    icon: TwitterIcon,
    href: "https://twitter.com/pepepalofficial",
  },
  {
    label: "Telegram",
    icon: TelegramIcon,
    items: [
      {
        label: "English",
        href: "https://t.me/pancakeswap",
      },
    /*  {
        label: "Bahasa Indonesia",
        href: "https://t.me/PancakeSwapIndonesia",
      },
      {
        label: "中文",
        href: "https://t.me/PancakeSwap_CN",
      },
      {
        label: "Tiếng Việt",
        href: "https://t.me/PancakeSwapVN",
      },
      {
        label: "Italiano",
        href: "https://t.me/pancakeswap_ita",
      },
      {
        label: "русский",
        href: "https://t.me/pancakeswap_ru",
      },
      {
        label: "Türkiye",
        href: "https://t.me/pancakeswapturkiye",
      },
      {
        label: "Português",
        href: "https://t.me/PancakeSwapPortuguese",
      },
      {
        label: "Español",
        href: "https://t.me/PancakeswapEs",
      },
      {
        label: "日本語",
        href: "https://t.me/pancakeswapjp",
      },
      {
        label: "Français",
        href: "https://t.me/pancakeswapfr",
      },
      {
        label: "Deutsch",
        href: "https://t.me/PancakeSwap_DE",
      },
      {
        label: "Filipino",
        href: "https://t.me/Pancakeswap_Ph",
      },
      {
        label: "ქართული ენა",
        href: "https://t.me/PancakeSwapGeorgia",
      },
      {
        label: "हिन्दी",
        href: "https://t.me/PancakeSwapINDIA",
      },
      {
        label: "Announcements",
        href: "https://t.me/PancakeSwapAnn",
      },
      */
    ],
  },
  {
    label: "Reddit",
    icon: RedditIcon,
    href: "https://reddit.com/r/pepepalofficial",
  },
  {
    label: "Instagram",
    icon: InstagramIcon,
    href: "https://instagram.com/pepepalofficial",
  },
 // {
  //  label: "Github",
  //  icon: GithubIcon,
  //  href: "https://github.com/pepepalofficial/",
 // },
  {
    label: "Medium",
    icon: MediumIcon,
    href: "https://medium.com/@WutHonker",
  },
  {
    label: "Youtube",
    icon: YoutubeIcon,
    href: "https://youtube.com/@pepepalofficial",
  },
];

export const langs: Language[] = [...Array(20)].map((_, i) => ({
  code: `en${i}`,
  language: `English${i}`,
  locale: `Locale${i}`,
}));
