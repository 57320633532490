import { ContextApi } from "@pancakeswap/localization";
import { FooterLinkType } from "../../../components/Footer/types";

export const footerLinks: (t: ContextApi["t"]) => FooterLinkType[] = (t) => [
  {
    label: t("About"),
    items: [
      {
        label: t("Contact"),
        href: "https://t.me/pepepalofficial",
        isHighlighted: true,
      },
    //  {
    //    label: t("WhitePeper"),
    //    href: "https://whitepaper.pepepal.com/whitepeper-v1/",
    //  },
      {
        label: t("Blog"),
        href: "https://medium.com/@WutHonker",
      },
      {
        label: t("Community"),
        href: "https://t.me/pepepalofficial",
      },
      {
        label: t("WhitePeper"),
        href: "https://whitepaper.pepepal.com/whitepeper-v1/",
      },
    ],
  },
  {
    label: t("Help"),
    items: [
    //  {
    //    label: t("Customer Support"),
    //    href: "https://whitepaper.pepepal.com/contact-us/customer-support",
    //  },
    //  {
    //    label: t("Troubleshooting"),
    //    href: "https://whitepaper.pepepal.com/help/troubleshooting",
    //  },
      {
        label: t("Guide"),
        href: "https://whitepaper.pepepal.com/whitepeper-v1/dex",
      },
    ],
  },
  {
    label: t("Developers"),
    items: [
      {
        label: "Github",
        href: "https://github.com/forkmeharderdaddy",
      },
    //  {
    //    label: t("Documentation"),
    //    href: "https://whitepaper.pepepal.com",
    //  },
    //  {
    //    label: t("Bug Bounty"),
    //    href: "https://whitepaper.pepepal.com/code/bug-bounty",
    //  },
    //  {
    //    label: t("Audits"),
    //    href: "https://whitepaper.pepepal.com/help/faq#is-pepedex-safe-has-pepedex-been-audited",
    //  },
    //  {
    //    label: t("Contact/Support/Inquiries "),
    //    href: "https://t.me/pepepaldevs",
    //  },
    ],
  },
];
