import { ChainId, ERC20Token, WETH9 } from '@pancakeswap/sdk'
import { USDC_GOERLI } from './common'

export const goerliTestnetTokens = {
  weth: WETH9[ChainId.GOERLI],
  celr: new ERC20Token(ChainId.GOERLI, '0x5D3c0F4cA5EE99f8E8F59Ff9A5fAb04F6a7e007f', 18, 'CELR', 'CelerToken', ''),
  leet: new ERC20Token(ChainId.GOERLI, '0xBd509651E6374c327d24b9d7E3Ea46704f6F31E8', 18, 'LEET', 'Leet Token', ''),
  pepl: new ERC20Token(ChainId.GOERLI, '0x535Fe3CCD4b860a865d10fAa60305EdABdf1Dd62', 9, 'PEPL', 'TestPepePal', ''),
  cake: new ERC20Token(ChainId.GOERLI, '0x1CFB6C873eDC8a67EFc3AFaB9e405aa9eD5e5189', 18, 'COPE', 'Cope', ''),
  dai: new ERC20Token(ChainId.GOERLI, '0xdc31Ee1784292379Fbb2964b3B9C4124D8F89C60', 18, 'DAI', 'Dai Stablecoin', ''), 
  usdc: USDC_GOERLI,
}
//0xdc31ee1784292379fbb2964b3b9c4124d8f89c60 DAI (etherscan is down so)