import { BigNumber } from '@ethersproject/bignumber'
import { Pool } from '@pancakeswap/uikit'
import { SerializedWrappedToken } from '@pancakeswap/token-lists'
import Trans from 'components/Trans'
import { VaultKey } from 'state/types'
import { bscTokens, goerliTestnetTokens, ethereumTokens } from '@pancakeswap/tokens'
import { PoolCategory } from './types'

export const MAX_LOCK_DURATION = 31536000
export const UNLOCK_FREE_DURATION = 604800
export const ONE_WEEK_DEFAULT = 604800
export const BOOST_WEIGHT = BigNumber.from('20000000000000')
export const DURATION_FACTOR = BigNumber.from('31536000')

 export const vaultPoolConfig = {
  [VaultKey.CakeVaultV1]: {
    name: <Trans>Auto COPE</Trans>,
    description: <Trans>Automatic restaking</Trans>,
    autoCompoundFrequency: 5000,
    gasLimit: 380000,
    tokenImage: {
      primarySrc: `/images/tokens/${ethereumTokens.cake.address}.svg`,
      secondarySrc: '/images/tokens/autorenew.svg',
    },
  },
  [VaultKey.CakeVault]: {
    name: <Trans>Stake PEPL</Trans>,
    description: <Trans>Stake, Earn – And more!</Trans>,
    autoCompoundFrequency: 5000,
    gasLimit: 600000,
    tokenImage: {
      primarySrc: `/images/tokens/${ethereumTokens.cake.address}.svg`,
      secondarySrc: '/images/tokens/autorenew.svg',
    },
  }, 
  [VaultKey.CakeFlexibleSideVault]: {
    name: <Trans>Flexible CAKE</Trans>,
    description: <Trans>Flexible staking on the side.</Trans>,
    autoCompoundFrequency: 5000,
    gasLimit: 500000,
    tokenImage: {
      primarySrc: `/images/tokens/${ethereumTokens.cake.address}.svg`,
      secondarySrc: '/images/tokens/autorenew.svg',
    },
  }, 
 } as const

export const livePools: Pool.SerializedPoolConfig<SerializedWrappedToken>[] = [
  {
    sousId: 0,
    stakingToken: ethereumTokens.cake,
    earningToken: ethereumTokens.cake,
    contractAddress: {
      97: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
      56: '0xa5f8C5Dbd5F286960b9d90548680aE5ebFf07652',
      1: '0x3E20Df3d9b8e0229283efC96F326D9dFe9659A8F',
      5: '0x415556e2284fd2507D437ebB1Bf7B134f1C88985',
    },
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '10',
    isFinished: false,
  },
  
  {
    sousId: 1,
    stakingToken: goerliTestnetTokens.cake,
    earningToken: goerliTestnetTokens.pepl,
    contractAddress: {
      5: '0x892f5fFAE9A9283ebE988B512AC4454033954135',
      97: '',
    },
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '1',
   // version: 3,
  },

  {
    sousId: 2,
    stakingToken: goerliTestnetTokens.weth,
    earningToken: goerliTestnetTokens.cake,
    contractAddress: {
      5: '0x8F8F185a10824Bc5B39BBE2eB63706454906cFC8',
      97: '',
    },
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: "1",
   // version: 3,
  },
  /*
  {
    sousId: 307,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.zbc,
    contractAddress: {
      56: '0xa683C30d47BCd31fB1399b80A4475bc960b903e3',
      97: '',
    },
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '10.52',
    version: 3,
  },
   */
].map((p) => ({
  ...p,
  stakingToken: p.stakingToken.serialize,
  earningToken: p.earningToken.serialize,
}))

// known finished pools
const finishedPools = [
  {
    sousId: 302,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.wmx,
    contractAddress: {
      56: '0xaEd58Af2c98dbF05d26B3E5b3cc809fc70D203ce',
      97: '',
    },
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.5787',
    version: 3,
  },
  /*

  */
].map((p) => ({
  ...p,
  isFinished: true,
  stakingToken: p.stakingToken.serialize,
  earningToken: p.earningToken.serialize,
}))

export default [...livePools, ...finishedPools] as Pool.SerializedPoolConfig<SerializedWrappedToken>[]
