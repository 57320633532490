import { FixedNumber } from '@ethersproject/bignumber'

export const FIXED_ZERO = FixedNumber.from(0)
export const FIXED_ONE = FixedNumber.from(1)
export const FIXED_TWO = FixedNumber.from(2)

export const masterChefAddresses = {
//  97: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',  ///WHY DO HAPPEN
//  56: '0xa5f8C5Dbd5F286960b9d90548680aE5ebFf07652',
  1: '0x3E20Df3d9b8e0229283efC96F326D9dFe9659A8F',
  5: '0x415556e2284fd2507D437ebB1Bf7B134f1C88985',
}

export const nonBSCVaultAddresses = {
//  1: '0x2e71B2688019ebdFDdE5A45e6921aaebb15b25fb',
  97: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
}
